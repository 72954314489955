export default {
  Menu: {
    alterar_conta: 'Change account',
    trocar_senha: 'Change password',
    gerenciar_usuarios: 'Manage profile',
    indique_ganhe: 'Refer and earn',
    about_the_user: 'Your user',
    alterar_idioma: 'Change language',
  },
}
