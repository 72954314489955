<template>
  <v-app-bar class="top-bar" app elevation="0" color="white" height="56">
    <v-btn
      id="toast-drawer"
      class="menu__button"
      icon
      @click.stop="toggleDrawer"
    >
      <v-icon id="toast-drawer-icon">mdi-menu</v-icon>
    </v-btn>
    <breadcrumbs v-if="!isMobile" />
    <v-spacer />
    <div class="d-flex justify-xl-end align-center mr-3">
      <usa-feedback
        v-if="currentFarm && hasPermission($p.farm.list) && isUSA"
        class="mr-4"
        :is-mobile="isMobile"
      />
      <farm-selector
        v-if="currentFarm && hasPermission($p.farm.list)"
        class="mr-4"
      />
      <df-button
        v-if="shouldShowUpgradePlan"
        flank="fa-pull-right pl-1 fa-sm"
        class="mr-3"
        icon="arrow-up"
        variant="secondary"
        color-icon="#39af49"
        @click="redirectToPlans"
      >
        {{ $t('plan.upgrade_plan') }}
      </df-button>
      <account-configs :has-permission="hasPermission($p.user.list)" />
    </div>
  </v-app-bar>
</template>

<script>
import AccountConfigs from '@/modules/menu/components/AccountConfigs.vue'
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs.vue'
import DfButton from '@/lib/components/Button/DfButton.vue'
import FarmSelector from '@/modules/menu/components/FarmSelector.vue'
import LayoutMixin from '@/components/LayoutMixin.vue'
import PermissionMixin from '@/components/Permission/PermissionMixin.vue'
import UsaFeedback from '@/modules/menu/components/UsaFeedback.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'TopBar',

  mixins: [PermissionMixin, LayoutMixin],

  components: {
    AccountConfigs,
    Breadcrumbs,
    DfButton,
    FarmSelector,
    UsaFeedback,
  },

  computed: {
    ...mapGetters('farms', ['currentFarm', 'farms']),
    ...mapGetters('layout', ['drawer']),
    ...mapGetters('user', ['isUSA']),
    shouldShowUpgradePlan() {
      return this.permissions?.length && !this.hasPermission(this.$p.menu.panel)
    },
  },

  methods: {
    ...mapActions('layout', ['setDrawer']),
    ...mapActions('user', ['changeLanguage']),
    toggleDrawer() {
      this.setDrawer(!this.drawer)
    },
    redirectToPlans() {
      this.$router.push({
        path: '/plans',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.top-bar {
  border-bottom: 1px solid #e6e9e6 !important;

  .menu__button {
    margin-left: 0px !important;
  }
}
::v-deep .v-toolbar__content {
  max-width: 1440px;
  margin: 0px auto;
  padding: 0px 0px 0px 8px;
}
</style>
