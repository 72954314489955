<template>
  <v-navigation-drawer
    id="sub-drawer"
    class="sub-drawer"
    :value="isSubDrawerOpened"
  >
    <v-list-item class="sub-drawer__header">
      <v-btn id="sub-drawer-return" icon @click="closeSubDrawer">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <h2 class="header-title ml-2">
        {{ name ? $t(`plan.menu.${name}`) : '' }}
      </h2>
      <v-spacer />
      <v-btn icon @click="closeDrawerAndSubDrawer">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-list-item>
    <v-divider class="sub-drawer__divider" />
    <loader v-if="loadingPlot" :show-loading-text="false" />
    <div
      v-else-if="!children.length"
      class="d-flex justify-center align-center sub-drawer__no-content"
    >
      {{ $t('plan.menu.no_result') }}
    </div>
    <div v-else class="sub-drawer__content">
      <template v-for="(item, index) in children">
        <v-list-item
          class="color-text-sub-drawer sub-drawer-nav pl-8"
          active-class="menu-active"
          :key="index"
          exact
          @click="checkActionAndClose(item)"
        >
          <v-list-item-icon class="mx-2">
            <font-awesome-icon
              :icon="item.icon"
              color="#788476"
              class="sub-drawer-icon"
            />
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="sub-drawer-title">
              {{ $t(item.label) || item.translatedLabel }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Loader from '@/components/Loaders/Loader.vue'

export default {
  name: 'SubDrawer',

  components: {
    Loader,
  },

  props: {
    children: {
      required: true,
      type: Array,
    },
    name: {
      required: true,
      type: String,
    },
  },

  computed: {
    ...mapGetters('farms', ['currentFarmId']),
    ...mapGetters('layout', ['isSubDrawerOpened']),
    ...mapGetters('plot', ['loadingPlot']),
  },

  methods: {
    ...mapActions('layout', ['setSubDrawer', 'closeDrawerAndSubDrawer']),
    checkActionAndClose(item) {
      this.$router.push({
        path: item.path,
        query: {
          plot_id: item.id,
          fazenda_id: this.currentFarmId,
        },
      })
      this.closeDrawerAndSubDrawer()
    },
    closeSubDrawer() {
      this.setSubDrawer(false)
    },
  },
}
</script>

<style lang="scss" scoped>
.sub-drawer {
  position: fixed;
  left: 256px;
  z-index: 6;
  transition: all 0.3s ease;

  @media (max-width: 960px) {
    left: 0px;
    z-index: 8;
  }
}
.sub-drawer__header {
  position: absolute;
  height: 61px;
  width: calc(100% - 10px);
  background-color: #fff;
  z-index: 1;

  .header-title {
    font-family: 'Rubik';
    font-size: 16px;
    font-weight: 600;
    color: #1a2b46;
  }
}
.sub-drawer__divider {
  position: absolute;
  top: 64px;
  width: 256px;
}
.sub-drawer__no-content {
  height: 100%;
  font-family: 'Rubik';
  font-size: 14px;
  font-weight: 500;
  color: #788476;
}
.sub-drawer__content {
  margin-top: 80px;
}
.sub-drawer-nav:hover {
  color: #39af49 !important;
}
.sub-drawer-title {
  font-family: 'Rubik';
  font-size: 16px;
  font-weight: 600;
  color: #788476;
}
.color-text-sub-drawer:hover {
  & .v-list-item__icon .sub-drawer-icon {
    color: #39af49;
  }
  & .v-list-item__content .sub-drawer-title {
    color: #39af49;
  }
}
</style>
