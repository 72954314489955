<template>
  <div class="d-flex">
    <div
      v-for="i in limit"
      :key="i"
      class="segment"
      :style="{ background: segmentColor(i) }"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',

  props: {
    progress: {
      type: Number,
      default: 0,
    },
    limit: {
      type: Number,
      default: 5,
    },
    segmentColors: {
      type: Boolean,
      default: false,
    },
    color: {
      type: Object,
      default: () => ({
        low: '#4caf50',
        medium: '#ffcc00',
        high: '#f4511e',
        default: '#e6e9e6',
      }),
    },
  },

  methods: {
    segmentColor(i) {
      const indexLimit = Math.round((this.limit * this.progress) / 100)
      if (this.segmentColors) {
        if (i <= indexLimit) {
          if (this.progress <= 50) {
            return this.color.low
          } else if (this.progress > 50 && this.progress <= 70) {
            return this.color.medium
          } else {
            return this.color.high
          }
        }
      } else {
        if (i <= indexLimit) {
          return this.color.low
        }
      }

      return this.color.default
    },
  },
}
</script>
<style>
.segment {
  width: 2.3rem;
  height: 0.28rem;
  background: rgb(217, 217, 217);

  border-radius: 10px;
}
.segment:not(:first-child) {
  margin-left: 2.2px;
}
</style>
