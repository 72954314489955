<template>
  <div class="d-flex justify-center pb-4">
    <v-card id="freemium-card">
      <v-card-title>
        <v-icon size="16">mdi-diamond-stone</v-icon>
        <span class="pl-2">
          {{ $t('freemium.component.title') }}
        </span>
      </v-card-title>
      <v-card-text> {{ $t('freemium.component.subtitle') }} </v-card-text>
      <v-spacer></v-spacer>
      <progress-bar
        :limit="limit"
        :progress="progressOfUse"
        class="pl-4 pr-4"
      />
      <v-card-text
        class="pt-1 pb-2"
        style="color: #5b6459"
        v-if="progressOfUse < 100"
      >
        {{ remainingLimit() }}
      </v-card-text>
      <v-card-text
        class="pt-1 pb-2"
        style="color: #5b6459"
        v-if="progressOfUse === 100"
      >
        {{ $t('freemium.component.exceeded_limit') }}
      </v-card-text>
      <v-card-actions>
        <v-btn color="success" block @click="openPaymentChoicesModal">
          {{ $t('freemium.button.title') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <plan-payment-choices-modal
      v-if="isPaymentModalOpened"
      :plan-priority="planLevels.light"
      @close="closePaymentChoicesModal"
    />
  </div>
</template>

<script>
import planLevels from '@/modules/plans/data/planLevels'
import PlanPaymentChoicesModal from '@/modules/payment/components/modal/PlanPaymentChoicesModal.vue'
import ProgressBar from '@/modules/menu/components/ProgressBar.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'LimitExceeded',

  components: {
    PlanPaymentChoicesModal,
    ProgressBar,
  },

  data() {
    return {
      isPaymentModalOpened: false,
      planLevels,
    }
  },

  computed: {
    ...mapGetters('freemium', ['limit', 'progressOfUse', 'usedLimit']),
    ...mapGetters('user', ['isBrazil']),
  },

  methods: {
    closePaymentChoicesModal() {
      this.isPaymentModalOpened = false
    },
    openPaymentChoicesModal() {
      if (this.isBrazil) {
        this.isPaymentModalOpened = true
      } else {
        this.$router.replace({
          path: '/plans',
        })
      }
    },
    remainingLimit() {
      return `${this.limit - this.usedLimit} ${this.$t(
        'freemium.component.remaining_limit'
      )}`
    },
  },
}
</script>

<style lang="scss" scoped>
#freemium-card {
  background-color: #f8f9f8;
  min-width: 200px;
}

#freemium-card .v-card__title {
  font-family: 'Rubik';
  font-size: 14px;
  color: #1a2b46;
  padding-bottom: 6px;
  padding-top: 8px;
}

#freemium-card .v-card__text {
  font-family: 'Rubik';
  font-size: 12px;
  color: #1a2b46;
  padding-bottom: 6px;
}

#freemium-card .v-btn {
  font-family: 'Rubik';
  font-size: 14px;
  text-transform: unset;
}
.v-navigation-drawer--mini-variant #freemium-card {
  display: none;
}
</style>
