<template>
  <df-button
    @click="redirectUSAFeedback"
    class="df-button--text-primary d-flex justify-space-around align-center"
    :show="!isMobile"
    :flank="'fa-pull-right fa-1x'"
    icon="share-square"
    width-slot="120px"
  >
    Submit feedback
  </df-button>
</template>

<script>
import DfButton from '@/lib/components/Button/DfButton.vue'

export default {
  name: 'UsaFeedback',

  components: {
    DfButton,
  },

  props: {
    isMobile: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    redirectUSAFeedback() {
      window.location.href = process.env.VUE_APP_USA_FEEDBACK
    },
  },
}
</script>
