export default {
  Menu: {
    alterar_conta: 'Alterar Cuenta',
    trocar_senha: 'Cambiar Contraseña',
    gerenciar_usuarios: 'Administrar Usuarios',
    indique_ganhe: 'Indique y gane',
    about_the_user: 'Tu usuário',
    alterar_idioma: 'Cambiar Idioma',
  },
}
