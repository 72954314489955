<template>
  <v-dialog :value="dialog" height="480px" width="668px" persistent eager>
    <v-card class="modal-card" :loading="loading">
      <v-toolbar flat>
        <v-toolbar-title class="modal-text-action pl-4"
          >{{ $t('one_question_before_continue') }}...
        </v-toolbar-title>
      </v-toolbar>

      <v-row no-gutters>
        <v-col cols="12">
          <div class="title-principal">
            {{ $t('what_is_your_job_title_today') }}?
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <div class="title-secundary">
            {{ $t('we_want_to_know_to_improve_your_experience') }}
          </div>
        </v-col>
      </v-row>

      <div class="body-modal d-flex flex-wrap">
        <v-row no-gutters v-for="jobTitle in jobTitles" :key="jobTitle.id">
          <v-col>
            <v-checkbox
              v-model="selectedJobTitle"
              item-value="jobTitle.id"
              class="pl-2 checkbox-row mt-n1"
              color="#39af49"
              hide-details
              :disabled="selectOneJobTitle(jobTitle.id)"
              :label="$t('job_titles.' + jobTitle.name)"
              :value="jobTitle.id"
            >
            </v-checkbox>
          </v-col>
        </v-row>
      </div>

      <v-card-actions class="card-actions-custom">
        <v-spacer class="pl-2"></v-spacer>
        <v-btn
          class="text-none button-view"
          :disabled="hasOneSelectedJobTitle"
          :loading="loading"
          @click="setJobTitle"
        >
          <span class="button-view-description"> {{ $t('salvar') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ModalJobTitle',
  data() {
    return {
      jobTitles: [],
      selectedJobTitle: [],
      loading: false,
    }
  },

  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    this.listJobTitles()
  },

  computed: {
    ...mapGetters('user', ['currentUser']),

    hasOneSelectedJobTitle() {
      return this.selectedJobTitle?.length === 0
    },
  },

  methods: {
    selectOneJobTitle(jobTitleId = null) {
      return (
        this.selectedJobTitle.length > 0 &&
        this.selectedJobTitle[0] !== jobTitleId
      )
    },

    async listJobTitles() {
      try {
        this.loading = true
        const response = await this.$api.jobTitles.get()

        response.data = response.data.filter(
          (jobTitle) => jobTitle.name !== 'manager_admin_digifarmz'
        )

        this.jobTitles = response.data
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },

    async setJobTitle() {
      try {
        this.loading = true
        await this.$api.jobTitles.set(
          this.currentUser?.id,
          this.selectedJobTitle[0]
        )

        this.$root.$emit('notify', 'success', this.$t('succesfuly_saved'))

        this.loading = false
        this.$emit('close')
      } catch (e) {
        this.loading = false
      }
    },
  },
}
</script>
<style scoped>
::v-deep .v-card__actions {
  padding: 15px 25px 10px 0px;
}
::v-deep .v-dialog {
  overflow: hidden;
}
.modal-text-action {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 20px;
  padding: 17px;
  white-space: normal;
}

.body-modal {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 500px;
  padding: 12px 24px;
  background: #f5f5f5 !important;
}

.checkbox-row {
  background: #e6e9e6;
  border-radius: 8px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  padding: 8px !important;
  margin: 14px 6px;
}

.title-principal {
  color: #1a2b46;
  font-family: Rubik;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  padding-left: 35px;
  padding-top: 35px;
}

.title-secundary {
  color: #788476;
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding-left: 35px;
  padding-bottom: 12px;
  padding-top: 8px;
}

.modal-card {
  background-color: #f5f5f5;
  border-radius: 8px;
}

.button-view-description {
  color: #fff;
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0;
}

.button-view {
  background-color: #39af49 !important;
}

.card-actions-custom {
  background-color: #fff;
  position: sticky;
  bottom: 0;
}
</style>
